<template>
  <div class="personalDetails">
    <top-bar title="户号同步" hasBack></top-bar>
    <van-form :colon="true" @submit="submit">
        <van-field
            v-model="wxUserInfo.phoneNum"
            label="电话号码"
            :border="false"
            size="large"
            required
            :rules="rules.phoneNum"
            class="van-filed"
        />
        <van-field
            v-model="wxUserInfo.smsVerifyCode"
            size="large"
            label="短信验证码"
            required
            :rules="rules.smsVerifyCode"
            class="van-filed"
        >
          <template #button>
            <van-button v-if="isCodeShow" size="small" color="#6192D6" @click="sendCode(wxUserInfo.phoneNum)">{{ sendMsg }}</van-button>
            <van-button v-if="!isCodeShow" size="small" color="#6192D6" disabled>{{ sendMsg }}</van-button>
          </template>
        </van-field>
        <div>
          <div class="bound_pd" style="padding: 0">
            <div style="margin: 16px;">
              <van-button block color="#2b8df7">
                一键同步
              </van-button>
            </div>
            <dl class="ts" style="margin: 31px">
          <dt>温馨提示</dt>
          <dd>
            为方便用户使用，当前功能可以一键同步京源掌厅小程序下绑定的户号信息
          </dd>
        </dl>
          </div>
        </div>
      </van-form>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar";
import { GoodsMixin } from "@/mixins/goodsMixin";
import ajax from "@/api";
export default {
  name: "personalDetails",
  mixins: [GoodsMixin],
  inject: ["reload"],
  components: { TopBar },
  data() {
    return {
      wxUserInfo:{},
      rules:{
        'phoneNum':[
          { required: true, message: '手机号不能为空' },
          {
            pattern:   /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入正确的手机号',
            trigger: 'change'
          }],
        'smsVerifyCode':[
          { required: true, message: '验证码不能为空' }
        ]
      },
      isCodeShow:true,
      sendMsg: '发送验证码',
    };
  },
  watch: {

  },
  created() {
    this.getList()
  },
  mounted() {

  },
  methods: {
    getList(){
      ajax.getWxUserInfo({
        userId: this.wxUserId,
      }).then((res) => {
        this.wxUserInfo = res.data || {};
      });
    },
    sendCode(phoneNum){
      ajax.sendCode({phoneNum}).then((res) => {
        if (res.code == 200){
          this.$toast.success('验证码发送成功');
          let endMsRes = new Date().getTime() + 60000; //当前时间戳加上一分钟的时间戳，相当于当前时间一分钟以后的时间戳
          this.time(endMsRes)
          this.isCodeShow = true
        } else {
          this.$toast.fail(res.msg);
          this.isCodeShow = true
        }
      })
    },
    submit(){
      ajax.guestNoSync({
        userId: this.wxUserInfo.userId,
        phoneNum: this.wxUserInfo.phoneNum,
        smsVerifyCode: this.wxUserInfo.smsVerifyCode
      }).then((res) => {
        if (res.code == 200){
          this.$toast.success('一键同步成功！');
          this.getList()
        }else{
          this.$toast.fail(res.msg);
        }
      })
    },
    //验证码倒计时
    time(endMsRes){
      this.isCodeShow = false;
      this.countDown = Math.ceil((endMsRes - new Date().getTime()) / 1000);
      this.sendMsg = this.countDown + "s";
      this.timeFun = setInterval(() => {
        this.countDown--;
        this.sendMsg = this.countDown + "s";
        if (this.countDown < 1) {
          this.isCodeShow = true;
          this.sendMsg = "重新发送";
          this.countDown = 60
          localStorage.removeItem("loginSendTime");
          clearInterval(this.timeFun);
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "style";
</style>
